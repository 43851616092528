<template>
  <div id="create">
    <v-speed-dial
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
      class="pb-4 mb-4"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary darken-2" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-menu
          </v-icon>
        </v-btn>
      </template>
      <!-- <v-btn fab dark small color="green">
        <v-icon>mdi-pencil</v-icon>
      </v-btn> -->
      <!-- <v-btn fab dark small color="primary">
        <v-icon>mdi-plus</v-icon>
      </v-btn> -->

      <v-btn
        fab
        dark
        small
        color="primary"
        @click="create"
        class="white--text"
        :disabled="disabled ? true : false"
      >
        <v-icon>{{ icon }}</v-icon>
        {{ title }}
      </v-btn>
      <!-- <v-btn fab dark small color="red">
        <v-icon>mdi-delete</v-icon>
      </v-btn> -->
    </v-speed-dial>
  </div>
</template>

<script>
export default {
  name: "AddButtonFab",
  props: {
    createFunction: { type: Function, required: true },
    icon: { type: String, required: true },
    title: { type: String, required: false },
    disabled: { type: Boolean, required: false },
  },

  data: () => ({
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "slide-y-reverse-transition",
  }),

  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },

  methods: {
    create() {
      this.createFunction();
    },
  },
};
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: fixed;
}

#create .v-btn--floating {
  position: relative;
}
</style>
